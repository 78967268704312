import Container from 'typedi';
import {
    AppIdentifiers,
    HostedClientLoadedEvent,
    IAuthenticateTopicData,
    IClientSettings,
    ILoginSuccessData,
    ILogoutSuccessData,
    IPlayerSegmentationData,
    ITopicSubscription,
    MessageBroker,
    OfferingNames,
    UserMode,
    IKambiClientSettings,
    IdentityChannel,
} from '@sparkware/uc-sdk-core';
import {
    ClientIntegrationFacadeToken,
    ClientSettingsToken,
    FeatureAvailabilityToken,
    LibraryManagerToken,
    UserContextToken,
    UserDataStoreDeferredObjectToken,
    WindowToken,
} from '../../../injection-tokens';
import {
    GetTokenErrorCodes,
    PreloaderComponentType,
    PreloaderElasticEvent,
} from '../../../models/enums/Consts';
import { ClientsFrameworkLogoutService } from '../../external/clients-framework';
import { IPreloaderComponentResponseData } from '../../ui/preloader/models/interfaces/IPreloaderComponentResponseData';
import { StorageItemEnum } from '../../../models/enums/storage-enums';
import TokenApi from '../../../../APIs/TokenApi';
import { ILogger, LoggerProvider } from '../../logger';
import { LocalSimpleStoreService } from '../../storage/implementations/simple-store';
import { EnvAccessAuthentication } from '../../environment-access-auth';
import { ABTestFeatures, Offering } from '../../action-handler/enums';
import EnvAccessAuthenticationConsts from '../../environment-access-auth/constants/EnvAccessAuthenticationConsts';
import { IClientInit } from './interfaces/IClientInit';
import { IClientIntegrationFacade } from '../interfaces/IClientIntegrationFacade';
import ContextApi from '../../../../APIs/ContextApi';
import { ApiResponse } from 'api';
import { RouterUtils } from '../../router/router.utils';
import { UrlUtils } from '../../utils/urlUtils';
import PageContextManager, { AuthenticatedPageContextData } from 'page-context-manager';
import { ISessionUserData } from '../../session-manager/interfaces/ISessionUserData';
import { IAppCatalog } from '../../app-catalog/interfaces';
import { AuthenticationUtils } from '../../authentication/utils/authentication.utils';
import { GlobalSubscriber } from '../../global-subscriber/global-subscriber';
import { Navigation } from '../../navigation';
import { IUserContext } from '../../user-context/user-context-interface';
import { RouterService } from '../../router/router.service';
import { ClientTracking } from '../../tracking/models/clientTracking';
import { Tracking } from '../../tracking/models/tracking';
import { WebVitalsTracking } from '../../tracking/models/web-vitals-tracking';
import { WebVitals } from '../../tracking/web-vitals';
import { IWebPushController } from '../../web-push/models/IWebPushController';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IPushController } from '../../push/models/IPushController';
import { IOptimizely } from '../../optimizely/interfaces';
import { IPostMessageHandler } from '../../post-message';
import { IBIEventHandler } from '../../bi/models/IBIEventHandler';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { ILoyaltyIndication } from '../../loyalty-indication/models/ILoyaltyIndication';
import { ViewInjector } from '../../view-injector';
import { GTMTracking } from '../../tracking/models/gtmTracking';
import { AnalyticsElasticTracking } from '../../tracking/models/analyticsElasticTracking';
import { WebVitalsElasticTracking } from '../../tracking/models/webVitalsElasticTracking';
import { Utils } from '../../utils';
import { NativeUtils } from '../../native/native.utils';
import { UserareaService } from '../../external/userarea';
import { ThemeController } from '../../theme/theme.controller';
import { SmartBannerController } from '../../smart-banner/smart.banner.controller';
import { B2CIntegration } from '../../b2c-integration';
import { WebLogin } from '../../web-login/web.login';
import { SessionState } from '../../session-manager/session-state';
import { GradualMigration } from '../../identity';
import { ILibraryManager } from '../../external/library/ILibraryManager';
import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';
import { IPreloaderManager } from '../../ui/preloader/models/interfaces/IPreloaderManager';
import { ILoginSuccessUCData } from '../../global-subscriber/mb-channels';

export abstract class ClientInit implements IClientInit {
    private _nativeUXVersion: number;
    private _loginSuccessSubscription: ITopicSubscription;
    // private modules
    private readonly _logger: ILogger;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _userDataStoreDeferred: DeferredObject<ISessionUserData>;
    private readonly _urlUtils: UrlUtils;
    private readonly _window: Window;
    private readonly _utils: Utils;
    private readonly _userareaService: UserareaService;
    private readonly _navigation: Navigation;
    private readonly _routerUtils: RouterUtils;
    private readonly _featureAvailability: IFeatureAvailability;
    private readonly _routerService: RouterService;
    private readonly _viewInjector: ViewInjector;
    private readonly _tracking: Tracking;
    private readonly _clientTracking: ClientTracking;
    private readonly _smartBannerController: SmartBannerController;
    private readonly _b2cIntegration: B2CIntegration;
    private readonly _gradualMigration: GradualMigration;
    private readonly _analyticsElasticTracking: AnalyticsElasticTracking;
    private readonly _elasticTrackingProvider: WebVitalsElasticTracking;
    private readonly _gtmTrackingProvider: GTMTracking;
    private readonly _webVitals: WebVitals;
    private readonly _webVitalsTracking: WebVitalsTracking;
    private readonly _cfLogoutService: ClientsFrameworkLogoutService;
    private readonly _libraryManager: ILibraryManager;
    private readonly _envAccessAuthentication: EnvAccessAuthentication;
    private readonly _authenticationUtils: AuthenticationUtils;
    private readonly _webLogin: WebLogin;
    private readonly _identityChannel: IdentityChannel;

    // protected modules
    protected readonly _userContext: IUserContext;
    protected readonly _clientIntegrationFacade: IClientIntegrationFacade;

    private get _webPushControllerPromise(): Promise<IWebPushController> {
        return LoaderManager.Instance.WebPushLoader.Instance;
    }

    private get _pushPromise(): Promise<IPushController> {
        return LoaderManager.Instance.PushLoader.Instance;
    }

    private get _optimizelyPromise(): Promise<IOptimizely> {
        return LoaderManager.Instance.OptimizelyLoader.Instance;
    }

    private get _appCatalogPromise(): Promise<IAppCatalog> {
        return LoaderManager.Instance.AppCatalogLoader.Instance;
    }

    private get _pendingHandlerPromise(): Promise<IPostMessageHandler> {
        return LoaderManager.Instance.PendingHandlerLoader.Instance;
    }

    private get _biEventHandlerPromise(): Promise<IBIEventHandler> {
        return LoaderManager.Instance.BIEventHandlerLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _loyaltyIndicationPromise(): Promise<ILoyaltyIndication> {
        return LoaderManager.Instance.LoyaltyIndicationLoader.Instance;
    }

    private get isBossMode(): boolean {
        return this._utils.isBossMode();
    }

    private get _preloaderManagerPromise(): Promise<IPreloaderManager> {
        return LoaderManager.Instance.PreloaderManagerLoader.Instance;
    }

    protected constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('ClientInit');
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._userDataStoreDeferred = Container.get(UserDataStoreDeferredObjectToken);
        this._userContext = Container.get(UserContextToken);
        this._urlUtils = Container.get(UrlUtils);
        this._window = Container.get(WindowToken);
        this._utils = Container.get(Utils);
        this._clientIntegrationFacade = Container.get(ClientIntegrationFacadeToken);
        this._userareaService = Container.get(UserareaService);
        this._navigation = Container.get(Navigation);
        this._routerUtils = Container.get(RouterUtils);
        this._featureAvailability = Container.get(FeatureAvailabilityToken);
        this._routerService = Container.get(RouterService);
        this._viewInjector = Container.get(ViewInjector);
        this._tracking = Container.get(Tracking);
        this._clientTracking = Container.get(ClientTracking);
        this._smartBannerController = Container.get(SmartBannerController);
        this._b2cIntegration = Container.get(B2CIntegration);
        this._gradualMigration = Container.get(GradualMigration);
        this._analyticsElasticTracking = Container.get(AnalyticsElasticTracking);
        this._elasticTrackingProvider = Container.get(WebVitalsElasticTracking);
        this._gtmTrackingProvider = Container.get(GTMTracking);
        this._webVitalsTracking = Container.get(WebVitalsTracking);
        this._webVitals = Container.get(WebVitals);
        this._cfLogoutService = Container.get(ClientsFrameworkLogoutService);
        this._libraryManager = Container.get(LibraryManagerToken);
        this._envAccessAuthentication = Container.get(EnvAccessAuthentication);
        this._authenticationUtils = Container.get(AuthenticationUtils);
        Container.get(GlobalSubscriber);
        Promise.allSettled([
            this._optimizelyPromise,
            this._biEventHandlerPromise,
            this._pendingHandlerPromise,
            this._sessionManagerPromise,
        ]).then((results) => {
            this._logger.debug('All required modules promises are settled: ', results);
        });
        this._webLogin = Container.get(WebLogin);
        Container.get(SessionState);
        Container.get(ThemeController);
        this._identityChannel = MessageBroker.getInstance().identity;
        this._identityChannel.topics.loginSuccess.subscribe(this._onLoginSuccess.bind(this));
        this._identityChannel.topics.logoutSuccess.subscribe(this._onLogoutSuccess.bind(this));
        this._init().then(() => {
            this._logger.info('ClientInit initiated successfully');
        });
    }

    public addRestrictedOfferings = async (
        authenticateData: IAuthenticateTopicData,
        isAutoLoginOnly?: boolean,
    ): Promise<void> => {
        const restrictedOfferings = PageContextManager.getUserData().restrictedOfferings;
        if (this._isPokerRestricted(restrictedOfferings, isAutoLoginOnly)) {
            restrictedOfferings.push(Offering[OfferingNames.Poker]);
        }
        let restrictedApps: string[];
        if (restrictedOfferings) {
            const appCatalog = await this._appCatalogPromise;
            restrictedApps = await appCatalog.getRestrictedApps(restrictedOfferings);
        } else {
            restrictedApps = [];
        }
        if (authenticateData) {
            authenticateData.RestrictedApps = restrictedApps;
        }
    };

    public async onClientLoad(): Promise<void> {
        const preloaderComponentResponseData: IPreloaderComponentResponseData = {
            isLoaded: true,
        };
        this._viewInjector.InjectClient();
        const preloaderManager = await this._preloaderManagerPromise;
        preloaderManager?.onComponentFinished(
            PreloaderComponentType.Client,
            preloaderComponentResponseData,
            PreloaderElasticEvent.ClientFinished,
        );
        this.subscribeToMessageBroker();
        this._webLogin.Init();
        await this._navigation.HandleDeeplinks(this._routerUtils.URI);
    }

    protected _messageBrokerSubscription = (): void => {
        const { playerSegmentationPublish } = this._clientIntegrationFacade;

        if (this._utils.getUserMode() === UserMode.Authenticated) {
            playerSegmentationPublish();
            this._b2cIntegration.playerSegmentationPublish();
        }
    };

    protected subscribeToMessageBroker(): void {
        const { ReadyToConnect, playerSegmentationPublish } = this._clientIntegrationFacade;
        if (ReadyToConnect.resolved) {
            this._messageBrokerSubscription();
        } else {
            ReadyToConnect.promise.then((ready) => {
                if (!ready) {
                    //possible deletion
                    this._logger.error(
                        "onClientLoad: _messageBrokerSubscription won't be called since the client is not able to communicate through MB events.",
                    );
                }
            });
        }
    }

    protected async onLoginSuccess(
        data: ILoginSuccessUCData,
        isLoginPublish: boolean = true,
    ): Promise<void> {
        this._gradualMigration.processMigrationData(data.SportMigrationData);
        const { loginPublish, playerSegmentationPublish } = this._clientIntegrationFacade;
        if (isLoginPublish) {
            const { AuthenticateData } = data || {};
            const { loginOptions } = data || {};
            if (loginOptions?.isAutoLoginOnly) {
                await this._registerPush();
            }
            await this.addRestrictedOfferings(AuthenticateData, loginOptions?.isAutoLoginOnly);
            loginPublish(AuthenticateData);
        }
        playerSegmentationPublish();
        this._b2cIntegration.playerSegmentationPublish();
    }

    protected async onLogoutSuccess(data: ILogoutSuccessData): Promise<void> {
        const { logoutPublish, playerSegmentationPublish } = this._clientIntegrationFacade;
        if (!this.isBossMode) {
            const { CID, Reason } = data || {};
            logoutPublish({ CID, Reason });
            playerSegmentationPublish();

            this._b2cIntegration.playerSegmentationPublish();
        } else this._localSimpleStoreService.remove(StorageItemEnum.IsBossMode);
        this._authenticationUtils.removeAuthorizationData();
        await this._routerService._handleMainViewApp();
        this._registerToLoginSuccessEvents();
    }

    protected async _clientLoginSuccessBossMode(): Promise<void> {
        if (!this.isBossMode) return;
        await this._cfLogoutService.doLogoutWithoutRefresh('', false);
    }

    private async _init(): Promise<void> {
        var isAutoLoginRequest = this._urlUtils.isAutologinRequest();
        this._checkNativeAppUpgradeRequired();
        this._registerSmartBanner();
        this._initNativeVersionParam();
        this._initNativeOsParams();
        this._registerToLoginSuccessEvents(isAutoLoginRequest);
        this._registerTracking();
        this._registerWebVitals();
        this._RegisterTrackJS();
        this._registerWebPush();
        const { tokenSubscribe } = this._clientIntegrationFacade;
        tokenSubscribe(this._tokenSubscriber.bind(this));
        this.registerClientLibraryReadyListener();
        this._tryAddEnvironmentAccessAuthentication();
        this.registerPushOnRefresh();
    }

    private _checkNativeAppUpgradeRequired = async () => {
        await this._userareaService.executeOnload(() => NativeUtils.checkAppUpgradeRequired());
    };

    private _registerSmartBanner(): void {
        this._smartBannerController.registerSmartBannerService();
    }

    private _initNativeVersionParam = (): void => {
        const qsVersion = new URL(location.href).searchParams.get('nativeUXVersion');
        const localStorageNativeUXVersion = this._localSimpleStoreService.get('nativeUXVersion');

        if (
            !localStorageNativeUXVersion ||
            (qsVersion && Number(qsVersion) > Number(localStorageNativeUXVersion))
        ) {
            const nativeUXVersion = qsVersion;

            if (nativeUXVersion) {
                this._nativeUXVersion = parseInt(nativeUXVersion);
                this._localSimpleStoreService.set(
                    'nativeUXVersion',
                    this._nativeUXVersion.toString(),
                );
            }
        } else {
            this._nativeUXVersion = parseInt(this._localSimpleStoreService.get('nativeUXVersion'));
        }

        this._window['__UAA_STATE__'].userContext.nativeUXVersion = this._nativeUXVersion;
    };

    private _initNativeOsParams(): void {
        const currentSearchParams = new URL(location.href).searchParams;
        const nativeOS = currentSearchParams.get('NativeOS');
        const nativeOSVersion = currentSearchParams.get('NativeOSVersion');
        if (nativeOS) {
            this._localSimpleStoreService.set('NativeOS', nativeOS.toString());
        }
        if (nativeOSVersion) {
            this._localSimpleStoreService.set('NativeOSVersion', nativeOSVersion.toString());
        }
    }

    private _onLoginSuccess = async (data: ILoginSuccessUCData): Promise<void> => {
        await this.onLoginSuccess(data);
    };

    private _onLogoutSuccess = async (data: ILogoutSuccessData): Promise<void> => {
        await this.onLogoutSuccess(data);
    };

    private _registerTracking(): void {
        this._tracking.addProvider(this._gtmTrackingProvider);
        this._tracking.addProvider(this._analyticsElasticTracking);
    }

    private _registerWebVitals(): void {
        this._webVitalsTracking.addProvider(this._elasticTrackingProvider);
        this._webVitals.registerHandlers();
    }

    private _RegisterTrackJS(): void {
        this._clientTracking.addTrackJsMetaData();
    }

    private _registerWebPush = async (): Promise<void> => {
        (await this._webPushControllerPromise)?.registerUrbanAirshipService();
    };

    private registerClientLibraryReadyListener(): void {
        const { ReadyToConnect } = this._clientIntegrationFacade;

        this._libraryManager.ClientLibrary.ready.then(
            (event: HostedClientLoadedEvent<IClientSettings | IKambiClientSettings>) => {
                const preClientInitPromises: [
                    Promise<ApiResponse<AuthenticatedPageContextData, any>>,
                    Promise<any>,
                    Promise<boolean>,
                    Promise<ISessionUserData>,
                ] = [
                    ContextApi.GetAuthenticatedContextData(),
                    TokenApi.GetToken(),
                    ReadyToConnect.resolved ? Promise.resolve(true) : ReadyToConnect.promise, // segmentation
                    this._userDataStoreDeferred.promise,
                ];

                Promise.all(preClientInitPromises)
                    .then(async ([authenticatedContextDataResponse, tokenResponse]) => {
                        let authenticateData: IAuthenticateTopicData;
                        let playerSegmentationData: IPlayerSegmentationData;

                        const { response, errorResponse } = authenticatedContextDataResponse;

                        //if authenticated
                        if (!errorResponse && tokenResponse?.Token) {
                            playerSegmentationData =
                                this._clientIntegrationFacade.getPlayerSegmentation();
                            authenticateData = this._getAuthenticateTopicData(
                                response,
                                tokenResponse?.Token,
                            );
                            await this.addRestrictedOfferings(authenticateData);
                        } else {
                            playerSegmentationData =
                                this._clientIntegrationFacade.getPlayerSegmentationInitial();
                        }

                        const routerUtils = Container.get(RouterUtils);

                        event.init({
                            clientSettings: Container.get(ClientSettingsToken).getClientSettings(),
                            messageBrokerChannels: MessageBroker.getInstance(),
                            authenticate: authenticateData,
                            segmentation: playerSegmentationData,
                            navigation: decodeURIComponent(
                                this._urlUtils.getRelativeURL(routerUtils.URI, {
                                    removeLanguage: true,
                                    removeBaseUrl: true,
                                }),
                            ),
                        });

                        await this.onClientLoad();
                    })
                    .catch((error) => {
                        this._logger.error(
                            `[registerClientLibraryReadyListener] failed: ${error} `,
                        );
                        const routerUtils = Container.get(RouterUtils);

                        event.init({
                            clientSettings: Container.get(ClientSettingsToken).getClientSettings(),
                            messageBrokerChannels: MessageBroker.getInstance(),
                            segmentation:
                                this._clientIntegrationFacade.getPlayerSegmentationInitial(),
                            navigation: decodeURIComponent(
                                this._urlUtils.getRelativeURL(routerUtils.URI, {
                                    removeLanguage: true,
                                    removeBaseUrl: true,
                                }),
                            ),
                        });
                        this.onClientLoad();
                    });
            },
        );
    }

    private _tryAddEnvironmentAccessAuthentication = async (): Promise<void> => {
        const env = PageContextManager.getEnvironmentData().environment;
        const { protectedEnvironments } = EnvAccessAuthenticationConsts;
        if (protectedEnvironments.some((i) => i == env?.toLowerCase())) {
            await this._envAccessAuthentication.init();
        }
    };

    private registerPushOnRefresh(): void {
        this._userDataStoreDeferred.promise.then(async () => {
            if (this._userContext.IsAuthenticated) {
                this._loginSuccessSubscription?.unsubscribe();
                const isAutoLoginRequest = this._urlUtils.isAutologinRequest();
                if (!isAutoLoginRequest) {
                    await this._registerPush();
                }
                await this._clientLoginSuccessBossMode();
            }
        });
    }

    private async _registerPush(): Promise<void> {
        const pushModule = await this._pushPromise;
        const isClientMode = this._urlUtils.isClientMode();
        if (!this.isBossMode && !isClientMode) {
            const { init } = await this._loyaltyIndicationPromise;
            const {
                onSubscribeTriple8,
                onFailTriple8Subscribe,
                subscribeToPushUpdates,
                registerPushUpdates,
            } = pushModule;

            subscribeToPushUpdates({
                name: 'Triple8',
                onSubscribed: (data) => {
                    onSubscribeTriple8(data);
                },
                onSubscribedFailed: onFailTriple8Subscribe,
            });

            subscribeToPushUpdates({
                name: 'LoyaltyIndication',
                onSubscribed: (data) => {
                    init(data);
                },
            });

            registerPushUpdates();
        }
    }

    private _getAuthenticateTopicData(
        authenticatedPageContextData: AuthenticatedPageContextData,
        token: string,
    ): IAuthenticateTopicData {
        return {
            CID: authenticatedPageContextData.user.cid,
            Token: token,
            HasKambiBets: authenticatedPageContextData.user.hasKambiBets,
            BatchNumber: authenticatedPageContextData.user.batchNumber,
            RestrictedApps: [], // This is being set at a next step !
            RegulationTypeID: authenticatedPageContextData.regulation.regulationTypeId,
        };
    }

    private _isPokerRestricted(RestrictedOfferings: number[], isAutoLoginOnly?: boolean): boolean {
        let isRestrictedOffering = RestrictedOfferings.includes(Offering[OfferingNames.Poker]);
        if (isRestrictedOffering) return true;

        const isFeatureEnabled = this._featureAvailability.IsFeatureEnabledABTestByProperties(
            ABTestFeatures[AppIdentifiers.PokerBlast],
            isAutoLoginOnly,
        );

        return isFeatureEnabled
            ? false
            : !this._featureAvailability.IsFeatureEnabled(AppIdentifiers.PokerBlast);
    }

    private _registerToLoginSuccessEvents = (isAutoLoginRequest?: boolean): void => {
        this._loginSuccessSubscription = this._clientIntegrationFacade.loginSuccessSubscribe(
            async () => {
                this._loginSuccessSubscription?.unsubscribe();
                if (!isAutoLoginRequest) {
                    await this._registerPush();
                }
                await this._clientLoginSuccessBossMode();
                this._registerSmartBanner();
            },
        );
    };

    private async _tokenSubscriber(): Promise<void> {
        const { initThirdPartyPublish } = this._clientIntegrationFacade;
        const getTokenResponse = await TokenApi.GetToken();
        if (getTokenResponse?.ErrorCode == GetTokenErrorCodes.NoRecordsFound) {
            await this._cfLogoutService.doLogoutAndShowTimeoutDialog();
        }
        const token = getTokenResponse && getTokenResponse.Token;
        initThirdPartyPublish(token);
    }
}
